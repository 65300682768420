import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  border-radius: 4px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  align-content: center;
`;

export const ProductImage = styled.div`
  max-width: 92px;
  max-height: 92px;
  padding: 8px;
  border: 1px solid #ccd2e3;
  border-radius: 4px;
  position: relative;

  img {
    width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 60px;
    max-height: 60px;
  }
`;

export const QuantityCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background: #001c72;
  color: #ffffff;
  opacity: 0.8;
  font-weight: 700;
  font-size: 16px;
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
`;

export const ProductName = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

export const Quantity = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const QuantityNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 31px;
  background: #ffffff;
  border-width: 1px 0;
  border-style: solid;
  border-color: #ccd2e3;
  user-select: none;
`;

export const Decrement = styled.button`
  width: 32px;
  height: 31px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #ccd2e3;
  border-radius: 40px 0 0 40px;
  background-color: #ffffff;
  cursor: pointer;

  svg {
    fill: #6677aa;
    width: 8px;

    &:hover {
      fill: #001c72;
    }
  }
`;

export const Increment = styled.button`
  width: 32px;
  height: 31px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #ccd2e3;
  border-radius: 0 40px 40px 0;
  background-color: #ffffff;
  cursor: pointer;

  svg {
    fill: #6677aa;
    width: 10px;

    &:hover {
      fill: #001c72;
    }
  }
`;

export const Price = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #001c72;
  margin-top: 16px;
`;
